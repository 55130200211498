import logo from './assets/Open.svg';
import logoU from "./assets/Logo.svg";
import './App.css';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Explain />} />
        <Route path="/inscription" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {

  const [state, setState] = useState(true)
  const [loading, setLoading] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [adress, setAdress] = useState("")

  const handleChangeFirstName = (event) => {
    setFirstName(event.target.value)
  }

  const handleChangeLastName = (event) => {
    setLastName(event.target.value)
  }

  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const handleChangeAdress = (event) => {
    setAdress(event.target.value)
  }

  const verifEmail = (mail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(mail);
  }

  const SubmitForm = () => {
      if(firstName.length > 0 && lastName.length > 0 && email.length > 0 && adress.length > 0) {
        if(verifEmail(email)) {
          setLoading(true)
          axios.post(process.env.REACT_APP_URL_API + "/api/snacks", {
            "FirstName": firstName,
            "LastName": lastName,
            "Email": email,
            "Address": adress,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "address": adress
          }, {headers: {"Content-Type": "application/ld+json"}}).then(res => {
            toast.success('L\'inscription est réussie !', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              })
              setState(false)
          }).catch(err => {
            toast.error('Une erreur est survenue', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
              setLoading(false)
          })
        } else {
          toast.error('L\'adresse email n\'est pas valide.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      } else {
        toast.error('Merci de remplir tous les champs.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
  }

  return (
    <div className="App">
    <div className="container">
      <header className="App-header">
        <br />
        <h1 className='App-title-jeu'>Du 9 au 20 septembre - <b className='App-bold-jeu'>Grand jeu</b></h1>
        <img src={logo} className="App-logo-golden" alt="logo" />
        {state &&
          <>
            <p>Maintenant que vous profitez de votre déjeuner, inscrivez-vous au tirage au sort !</p>
            <p>Des cadeaux exceptionnels sont à gagner...</p>
          </>
        }
      </header>
      <div className="App-form">
        {state ? (
          <>
            <div className="custom-input-container">
              <label className="custom-label">Prénom*:</label>
              <input className="custom-input" value={firstName} onChange={(event) => handleChangeFirstName(event)} />
            </div>
            <div className="custom-input-container">
              <label className="custom-label">Nom*:</label>
              <input className="custom-input" value={lastName} onChange={(event) => handleChangeLastName(event)} />
            </div>
            <div className="custom-input-container">
              <label className="custom-label">Email*:</label>
              <input className="custom-input" value={email} onChange={(event) => handleChangeEmail(event)} />
            </div>
            <div className="custom-input-container">
              <label className="custom-label">Adresse*:</label>
              <input className="custom-input" value={adress} onChange={(event) => handleChangeAdress(event)} />
            </div>
            <p>*champs à remplir</p>

            <button className='button-jeu' onClick={SubmitForm}>
              {loading ? (
                <>
                  <div class="loader"></div>
                </>
              ) : (
                <>
                  <b className='button-title'>Validez</b><br />
                  <b className='button-subtitle'>votre inscription</b>
                </>
              )}
            </button>
          </>
        ) : (
          <>
            <p className='p-result'>Merci d'avoir participé à notre grand jeu <br /> pour tenter de gagner des <br /> cadeaux exceptionnels !!!</p>
            <p className='p-result'>Les noms des gagnants seront révélés <br /> après le tirage au sort qui aura lieu <br /> le 23 septembre 2024.</p>
            <p className='p-result'>Les gagnants recevront par mail <br /> les modalités pour recevoir leur cadeau !!! </p>
            <p className='p-result'>A très bientôt, chez U Place Hoche !!!</p>
            <img src={logoU} className="App-logo" alt="logo" />
          </>
        )}

      </div>
    </div>
    <ToastContainer />
  </div>
  )
}

function Explain() {
  return (
    <div className="App">
      <div className="container">
        <header className="App-header">
          <img src={logoU} className="App-logo" alt="logo" />
          <h1 className='App-title'>À partir du 9 septembre</h1>
          <h1 className='App-title-bold'>Grand Jeu*</h1>
          <img src={logo} className="App-logo-golden" alt="logo" />
          <br />
          <Link to={"/inscription"}>
            <button className='App-home-button'>JE PARTICIPE</button>
          </Link>
        </header>
      </div>
    </div>
  )
}

export default App;
